import React from 'react';
import ReactDOM from 'react-dom/client';
import './global.css';
import App from './App';

// Extend the Window interface to include 'gtag'
declare global {
  interface Window {
    gtag?: (...args: any[]) => void;
    dataLayer?: Record<string, any>[];
  }
}

// University mapping
export const UNIVERSITY = {
  JAIN: 'jo',
  VIGNAN: 'vo',
  YENEPOYA: 'yo',
  AMITY: 'am',
  CHANDIGRAHUNIVIDERSITY: 'cu',
  DPU: 'dpu',
  ONLINEMANIPAL: 'om',
  SMU: 'smu',
  AMR: 'amr',
  LO: 'lo',
  SO: 'so',
  IIIT: 'iiit'
};

// University-based send_to IDs
const UNIVERSITY_SEND_TO_IDS: Record<string, string> = {
  jo: 'AW-4131458/JO123',
  vo: 'AW-4131458/VO456',
  yo: 'AW-4131458/YO789',
  am: 'AW-16659552284/-VGQCIbLq8cZEJyw8oc-',
  cu: 'AW-641313279/LhiECLyNps8BEP_T5rEC',
  dpu: 'AW-16635539124/UjlWCOakqcAZELTduPw9',
  om: 'AW-16635249015/FncyCPbQz5YaEPeCp_w9',
  smu: 'AW-16635249015/FncyCPbQz5YaEPeCp_w9',
  amr: 'AW-16531937580/TqujCIfrh5caEKyyhcs9',
  lo: 'AW-4131458/LO707',
  so: 'AW-16644178193/cbbXCJKUlMMZEJGCyIA-',
  iiit: 'AW-16588612423/lvQGCJ7OlZYaEMfGiOY9'
};

// University-based GTM IDs
const UNIVERSITY_GTM_IDS: Record<string, string> = {
  am: 'GTM-NL6HLHX5', // Amity University
  dpu: 'GTM-K3ZMS8FR', // DPU University
  vo: 'GTM-52F76TCV', // Vignan University
  iiit: 'GTM-KGGSHP6H', // IIIT Dharwad
  so: 'GTM-MNW5HS7F', // Sharda University
  om: 'GTM-MQZJDHFB', // online manipal
  smu: 'GTM-MQZJDHFB', // Sikkim Manipal
  amr: 'GTM-P2ZR4CTF', // Amritha University
  cu: 'GTM-PTDM549J', // Chandigarh University
  jo: 'GTM-WSSG693' // Jain University
};

// Function to dynamically create a favicon based on 'u'
function updateFavicon() {
  const params = new URLSearchParams(window.location.search);
  const u = params.get('u');

  let faviconElement = document.querySelector("link[rel='icon']");
  if (!faviconElement) {
    faviconElement = document.createElement('link');
    faviconElement.setAttribute('rel', 'icon');
    document.head.appendChild(faviconElement);
  }

  const canvas = document.createElement('canvas');
  canvas.width = 64;
  canvas.height = 64;
  const ctx = canvas.getContext('2d');

  if (ctx) {
    ctx.fillStyle = '#fff';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = '#000';
    ctx.font = '48px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(u || 'F', canvas.width / 2, canvas.height / 2);
  }

  faviconElement.setAttribute('href', canvas.toDataURL('image/png'));
}

// Function to inject GTM script based on university
function injectGTM() {
  const params = new URLSearchParams(window.location.search);
  const u = params.get('u');

  // Find the corresponding GTM ID
  const gtmId = UNIVERSITY_GTM_IDS[u as keyof typeof UNIVERSITY_GTM_IDS];

  if (gtmId) {
    (function(w: any, d: Document, s: string, l: string, i: string) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      const f = d.getElementsByTagName(s)[0];
      
      // Explicitly define the script element to avoid TypeScript errors
      const j = d.createElement(s) as HTMLScriptElement;
      
      const dl = l !== 'dataLayer' ? '&l=' + l : '';
      j.async = true;  // Ensures the script loads asynchronously
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;  // Correct GTM URL
      
      if (f.parentNode) {
        f.parentNode.insertBefore(j, f);
      }
    })(window, document, 'script', 'dataLayer', gtmId);

    console.log(`GTM script injected for university: ${u}, GTM ID: ${gtmId}`);
  } else {
    console.log('No GTM ID configured for this university.');
  }
}

// Function to trigger Google Ads conversion event
function triggerConversionEvent() {
  const params = new URLSearchParams(window.location.search);
  const u = params.get('u');

  const universityKey = Object.keys(UNIVERSITY).find(
    (key) => UNIVERSITY[key as keyof typeof UNIVERSITY] === u
  );

  const sendToId = universityKey
    ? UNIVERSITY_SEND_TO_IDS[u as keyof typeof UNIVERSITY_SEND_TO_IDS]
    : 'AW-4131458/DEFAULT';

  if (window.gtag) {
    window.gtag('event', 'conversion', {
      'send_to': sendToId,
    });
    console.log(`Google Ads conversion event triggered for university: ${universityKey}, send_to: ${sendToId}`);
  } else {
    console.warn('gtag not found on window');
  }
}

// Call functions after DOM has loaded
document.addEventListener('DOMContentLoaded', () => {
  updateFavicon();
  injectGTM();
  triggerConversionEvent();
});

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(<App />);
